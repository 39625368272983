<template>
  <a-dropdown placement="bottomCenter">
    <div class="ele-admin-header-avatar">
      <a-avatar
        :src="
          userInfo?.portraitUrl ||
          require('@/assets/images/user-face-default.png')
        "
        :size="28"
      />
      <!-- <span>&nbsp;&nbsp;{{ username }}</span> -->
      <down-outlined />
    </div>
    <template #overlay v-if="userInfo">
      <a-menu @click="handleUserDropClick" style="min-width: 220px">
        <a-menu-item key="switchSystem" style="line-height: 50px">
          <div class="dropdown-menu-item__content">
            {{ teamInfo?.teamName || "选择团队" }}
            <SwapOutlined
              class="switch-btn"
              style="color: #1969f1; font-size: 16px"
            />
          </div>
        </a-menu-item>
        <a-menu-divider style="margin: 4px 10px" />
        <a-menu-item key="switchApply" style="line-height: 50px">
          <div class="dropdown-menu-item__content">
            {{ applyInfo?.applicationName || "选择应用" }}
            <SwapOutlined
              class="switch-btn"
              style="color: #1969f1; font-size: 16px"
            />
          </div>
        </a-menu-item>
        <a-menu-divider style="margin: 4px 10px" />
        <a-menu-item key="" style="line-height: 22px">
          <div class="dropdown-menu-item__content">
            <a-space
              :size="6"
              direction="vertical"
              style="width: 100%; display: flex"
            >
              <span>{{ userInfo?.nickName || username }}</span>
              <span style="color: #8a909f">{{ mobileNo }}</span>
            </a-space>
            <span class="glzh-btn" style="font-size: 12px">
              管理账号
              <RightOutlined />
            </span>
          </div>
        </a-menu-item>
        <a-menu-divider style="margin: 4px 10px" />
        <a-menu-item key="logout">
          <div class="ele-cell">
            <div class="ele-cell-content">退出登录</div>
          </div>
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useStore } from "@/store/index";
import { useRouter } from "vue-router";
import { SwapOutlined } from "@ant-design/icons-vue";
import setting from "@/config/setting";
import useUser from "@/use/useUser";

export default defineComponent({
  props: {
    hideMenu: {
      type: Boolean,
      default: false,
    },
  },
  components: { SwapOutlined },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { userInfo, username, mobileNo, teamInfo, applyInfo } = useUser();

    const handleUserDropClick = ({ key }: any) => {
      if (key === "logout") {
        // 退出登录
        store.dispatch("user/Logout").then(() => {
          // router.removeRoute("BasicLayout");
          router.replace(setting.loginPath);
        });
      } else if (key === "switchSystem") {
        router.push("/system/selectSystem");
      } else if (key === "personalAccount") {
        router.push("/system/personalInfo");
      } else if (key === "switchApply") {
        router.push("/system/applyCenter");
      }
    };

    return {
      userInfo,
      username,
      mobileNo,
      teamInfo,
      applyInfo,
      handleUserDropClick,
    };
  },
});
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 48px;
  line-height: 48px;
  background: white;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 0;
  padding-left: 20px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  .switch-icon {
    flex: 0 0 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8c8c8c;
    cursor: pointer;
    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }
  .apply-name {
    margin-left: 15px;
    flex: 0 0 122px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #262626;
  }
  .menu {
    flex: auto;
    &::v-deep {
      .ant-menu {
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
      }
      .ant-menu-horizontal {
        border-bottom: none;
        & > .ant-menu-item::after,
        & > .ant-menu-submenu::after {
          bottom: 8px;
          height: 4px;
          background: transparent;
          border-radius: 3px;
          border-bottom: none;
          width: 30%;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .ant-menu-horizontal:not(.ant-menu-dark)
        > .ant-menu-item-selected::after {
        background: #00a389;
      }
    }
  }
  .header-tool {
    display: flex;
    flex-shrink: 0;
    padding: 0 12px;
    .header-tool-item {
      padding: 0 12px;
      font-size: 12px;
      transition: color 0.2s, background-color 0.2s;
      cursor: pointer;
      &:hover {
        background: rgba(0, 0, 0, 0.025);
      }
    }
  }
}

.dropdown-menu-item__content {
  position: relative;
  &:hover {
    .switch-btn {
      display: block;
    }
  }
  .switch-btn,
  .glzh-btn {
    color: #1969f1;
    position: absolute;
    top: 50%;
    right: 0;
    transform: transLateY(-50%);
  }
  .switch-btn {
    display: none;
  }
}
</style>
