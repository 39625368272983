
import { useRequest } from "xz-use";
import { computed, defineComponent, provide, ref } from "vue";
import { useRoute } from "vue-router";
import UploadImg from "@/components/UploadImg/index.vue";
import { message } from "ant-design-vue";
import DetailModal from "./detail/index.vue";
import FormItemGroup from "@/components/FormItemGroup.vue";
import { useForm } from "@/use/useForm";
import { userInfoCurrentUser, userInfoCurrentUserInfoSet } from "./server";

const initData = () => {
  return {
    portraitUrl: null,
    nickName: null,
    mobileNo: null,
  };
};

export default defineComponent({
  components: {
    UploadImg,
    DetailModal,
    FormItemGroup,
  },
  setup() {
    const detailRef = ref();
    const { form, formRef, getFieldsValue } = useForm(initData());
    const portraitUrl = computed({
      get: () => (form.value.portraitUrl ? [form.value.portraitUrl] : []),
      set: ([val]) => (form.value.portraitUrl = val),
    });

    //提交表单接口
    const { run: addRun, loading: addLodaing } = useRequest(
      userInfoCurrentUserInfoSet,
      {
        manual: true,
        onSuccess: () => {
          message.success("操作成功");
        },
      }
    );
    //请求详情接口
    const { run: detailRun, data: userInfo } = useRequest(
      () => {
        return userInfoCurrentUser();
      },
      {
        onSuccess(data) {
          console.log(data);
          form.value = getFieldsValue(data);
        },
      }
    );

    //提交
    const ok = async () => {
      try {
        await formRef.value.validate();
        addRun({
          ...form.value,
        });
      } catch (error) {
        console.log(error);
      }
    };
    provide("userInfo", userInfo);

    return {
      ok,
      formRef,
      form,
      addLodaing,
      detailRef,
      portraitUrl,
    };
  },
});
