
import { defineComponent } from "vue";
import { useStore } from "@/store/index";
import { useRouter } from "vue-router";
import { SwapOutlined } from "@ant-design/icons-vue";
import setting from "@/config/setting";
import useUser from "@/use/useUser";

export default defineComponent({
  props: {
    hideMenu: {
      type: Boolean,
      default: false,
    },
  },
  components: { SwapOutlined },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { userInfo, username, mobileNo, teamInfo, applyInfo } = useUser();

    const handleUserDropClick = ({ key }: any) => {
      if (key === "logout") {
        // 退出登录
        store.dispatch("user/Logout").then(() => {
          // router.removeRoute("BasicLayout");
          router.replace(setting.loginPath);
        });
      } else if (key === "switchSystem") {
        router.push("/system/selectSystem");
      } else if (key === "personalAccount") {
        router.push("/system/personalInfo");
      } else if (key === "switchApply") {
        router.push("/system/applyCenter");
      }
    };

    return {
      userInfo,
      username,
      mobileNo,
      teamInfo,
      applyInfo,
      handleUserDropClick,
    };
  },
});
