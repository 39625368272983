<script>
import { Col, Row } from "ant-design-vue";

export default {
  name: "FormItemGroup",
  props: {
    gutter: {
      type: Array,
      default: () => [6, 16],
    },
  },
  setup(props, { slots }) {
    return () => {
      const children = slots.default?.() ?? [];
      const { gutter } = props;
      return (
        <div class="form-item-group">
          {/*line-height top  显示错误信息时对齐*/}
          <Row gutter={gutter} align="top" style="line-height:32px">
            {children.map((child) => (
              <Col>{child}</Col>
            ))}
          </Row>
        </div>
      );
    };
  },
};
</script>

<style lang="less" scoped>
.form-item-group {
  display: inline-flex;
  //清除所有FormItem的margin-bottom;
  :deep(.ant-row.ant-form-item) {
    margin: 0 !important;
  }
}
</style>
