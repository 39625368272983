<template>
  <a-modal
    title="创建新团队"
    :width="600"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @cancel="handleClose"
    @ok="handleOk"
  >
    <a-form ref="formRef" :model="form" v-bind="formLayout">
      <a-form-item
        label="团队名称"
        name="teamName"
        :rules="[{ required: true, message: '请输入团队名称' }]"
      >
        <div class="team-name" :data-count="dataCount">
          <a-input
            placeholder="请输入团队名称"
            v-model:value="form.teamName"
            :maxlength="15"
          />
        </div>
        <!-- <span class="limit">{{ form.teamName.length }} / 15</span> -->
      </a-form-item>
      <a-form-item
        label="团队概况"
        name="teamContent"
        :rules="[{ required: true, message: '请输入团队概况' }]"
      >
        <a-textarea
          placeholder="请输入团队概况"
          v-model:value="form.teamContent"
          :rows="6"
          showCount
          :maxlength="150"
        />
      </a-form-item>
      <a-form-item label="管理员" name="name"> {{ mobileNo }} </a-form-item>
    </a-form>
  </a-modal>
</template>
<script lang="ts">
import { message } from "ant-design-vue";
import { defineComponent, computed } from "vue";
import { useModalState, useRequest } from "xz-use";
import { xzcloudTeamAdd } from "../service";
import { useForm, getDefaultFormValue } from "./use";
import useUser from "@/use/useUser";

export default defineComponent({
  setup(props, { expose, emit }) {
    const { form, formRef } = useForm();
    const { mobileNo } = useUser();

    // Modal状态管理
    const { visible, handleOpen, handleClose } = useModalState({
      afterClose: () => {
        form.value = getDefaultFormValue();
        formRef.value.clearValidate();
      },
    });

    expose({
      open: handleOpen,
      close: handleClose,
    });
    //     computed(() => {
    // data-count
    //     });
    const dataCount = computed(() => {
      return `${form.value.teamName.length} / 15`;
    });

    const { run: addRun, loading } = useRequest(xzcloudTeamAdd, {
      manual: true,
      onSuccess: () => {
        message.success("创建成功");
        emit("reload");
        handleClose();
      },
    });

    const handleOk = async () => {
      try {
        await formRef.value?.validateFields();
        await addRun(form.value);
      } catch (e) {
        console.error(e);
      }
    };

    return {
      loading,
      form,
      formRef,
      visible,
      mobileNo,
      dataCount,
      handleClose,
      handleOk,
      formLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
      },
    };
  },
});
</script>

<style lang="less" scoped>
.team-name {
  &::after {
    display: block;
    color: rgba(0, 0, 0, 0.45);
    text-align: right;
    content: attr(data-count);
    margin-bottom: -22px;
  }
}
</style>
