<template>
  <div class="container">
    <a-space class="top-wrap">
      <h2>我的团队</h2>
      <a-input-search
        placeholder="请输入团队名称"
        style="width: 200px"
        v-model:value="param"
        @search="searchTeam"
      />
    </a-space>

    <ul>
      <li @click="creatTeamModalRef?.open()">
        <a-space direction="vertical" class="creat-team">
          <PlusOutlined style="font-size: 26px" />
          <div>创建团队</div>
        </a-space>
      </li>
      <li
        v-for="item in dataSource"
        :key="item.teamId"
        @click="handleSelectTeam(item)"
      >
        <div class="content">
          <h3>
            {{ item.teamName }}
          </h3>
          <a-tooltip placement="top" color="#fff">
            <template #title>
              <span style="color: #909399">{{ item.teamContent }}</span>
            </template>
            <div class="desc">
              {{ item.teamContent }}
            </div>
          </a-tooltip>
          <div class="period">{{ item.createDate }} 创建</div>
        </div>
        <div class="btn-wrap">
          <a-button type="link">进入团队</a-button>
        </div>
      </li>
    </ul>
    <!-- <a-empty
      v-else
      description="暂无团队"
      style="padding-top: 100px; font-size: 14px"
    /> -->
  </div>
  <CreatTeamModal ref="creatTeamModalRef" @reload="searchTeam" />
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { useRequest } from "xz-use";
import { PlusOutlined } from "@ant-design/icons-vue";
import { useStore } from "@/store";
import CreatTeamModal from "./components/CreatTeamModal.vue";
import { xzcloudTeamSelectList } from "./service";

export default defineComponent({
  components: { PlusOutlined, CreatTeamModal },
  setup() {
    const creatTeamModalRef = ref<any>(null);
    const router = useRouter();
    const store = useStore();
    const param = ref<string>("");

    // 查询所有团队
    const { data: dataSource, run: searchTeam } = useRequest(() =>
      xzcloudTeamSelectList({ param: param.value })
    );

    // 选择团队
    const handleSelectTeam = async (item) => {
      store.dispatch("user/CacheSwitchSysInfo", {
        teamInfo: item,
      });
      // router.removeRoute("BasicLayout");
      router.push("/system/applyCenter");
    };

    return {
      creatTeamModalRef,
      dataSource,
      param,
      handleSelectTeam,
      searchTeam,
    };
  },
});
</script>
<style lang="less" scoped>
.container {
  width: 1080px;
  // height: 640px;
  min-height: 640px;
  background: #ffffff;
  border-radius: 8px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 30px 36px;
  .top-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  h2 {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #393c42;
    line-height: 28px;
    // margin-bottom: 20px;
    margin-bottom: 0;
  }
  ul {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    li {
      width: 320px;
      height: 184px;
      margin-right: 24px;
      margin-bottom: 24px;
      background: #ffffff;
      box-shadow: 0px 6px 18px 1px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      border: 1px solid #e9e9e9;
      cursor: pointer;
      &:nth-child(3n) {
        margin-right: 0;
      }
      &:hover {
        box-shadow: 0px 10px 32px 0px rgba(0, 0, 0, 0.1);
        border: 1px solid #00a389;
      }

      h3 {
        border-radius: 4px 4px 0px 0px;
        font-size: 16px;
        font-weight: 500;
        color: #303133;
        line-height: 24px;
        &:deep(.ant-tag) {
          border: 1px solid @primary-color;
          color: @primary-color;
          background: none;
          margin-left: 6px;
        }
      }
      .content {
        height: 140px;
        padding: 22px 22px 0 22px;
        border-bottom: 1px solid #ebeef5;
        box-sizing: border-box;
        .desc {
          height: 44px;
          margin-bottom: 8px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #909399;
          line-height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .period {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8a909f;
          line-height: 20px;
          bottom: 24px;
        }
      }
      .btn-wrap {
        height: 44px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
    .creat-team {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #f6f7f9;
      color: #8a909f;
      border-radius: 4px;
    }
  }
}
</style>
