
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import Header from "./components/Header.vue";

export default defineComponent({
  components: { Header },
  setup() {
    const route = useRoute();
    const hideApplyCenter = computed(() => {
      return (
        route.path === "/system/selectSystem" ||
        route.path === "/auth/authorize"
      );
    });
    const hideAuthQuery = computed(() => {
      return route.path !== "/auth/authorize";
    });
    return {
      hideApplyCenter,
      hideAuthQuery,
    };
  },
});
