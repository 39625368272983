import { httpGet, httpPost } from "@/config/axios-config";

//修改密码
export const userInfoUpdatePassword = (params) => {
  return httpPost("/gmcuser/userInfo/updatePassword", params);
};
//修改用户信息设置
export const userInfoCurrentUserInfoSet = (params) => {
  return httpPost("/gmcuser/userInfo/currentUserInfoSet", params);
};

export const userInfoCurrentUser = () => {
  return httpGet("/gmcuser/userInfo/currentUser");
};

export const commonMsgsend = (params) => {
  return httpGet("/common/msg/send", params);
};
