
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { useRequest } from "xz-use";
import { PlusOutlined } from "@ant-design/icons-vue";
import { useStore } from "@/store";
import CreatTeamModal from "./components/CreatTeamModal.vue";
import { xzcloudTeamSelectList } from "./service";

export default defineComponent({
  components: { PlusOutlined, CreatTeamModal },
  setup() {
    const creatTeamModalRef = ref<any>(null);
    const router = useRouter();
    const store = useStore();
    const param = ref<string>("");

    // 查询所有团队
    const { data: dataSource, run: searchTeam } = useRequest(() =>
      xzcloudTeamSelectList({ param: param.value })
    );

    // 选择团队
    const handleSelectTeam = async (item) => {
      store.dispatch("user/CacheSwitchSysInfo", {
        teamInfo: item,
      });
      // router.removeRoute("BasicLayout");
      router.push("/system/applyCenter");
    };

    return {
      creatTeamModalRef,
      dataSource,
      param,
      handleSelectTeam,
      searchTeam,
    };
  },
});
