import { httpGet, httpPost } from "@/config/axios-config";
import { PageParams, PageRes, PaginatedParams } from "@/services/public";

// 应用列表查询
export interface XzcloudApplicationSelectListRes {
  /* 应用ID */
  applicationId: string;

  /* 应用名称 */
  applicationName: string;

  /* 应用类型 */
  applicationType: string;

  /* 是否有容器 */
  hasContainer: boolean;

  /* 审核状态 */
  checkStatus: number;

  /* 系统类型 */
  type: number;
}
export const xzcloudApplicationSelectList = () =>
  httpGet<XzcloudApplicationSelectListRes[]>("/xzcloud/application/selectList");
