<template>
  <div>
    <div class="ele-body">
      <a-card>
        <a-form
          ref="formRef"
          :model="form"
          v-bind="{
            labelCol: { md: { span: 3 }, sm: { span: 24 } },
            wrapperCol: { md: { span: 15 }, sm: { span: 24 } },
          }"
        >
          <a-form-item label="个人账号">
            <form-item-group>
              <a-form-item
                name="mobileNo"
                :rules="[{ required: false, message: '个人账号不能为空' }]"
              >
                <a-input
                  :disabled="true"
                  v-model:value="form.mobileNo"
                  placeholder="请输入个人账号"
                />
              </a-form-item>
              <a-button size="small" type="link" @click="detailRef.open()"
                >修改密码</a-button
              >
            </form-item-group>
          </a-form-item>
          <a-form-item
            label="昵称"
            name="nickName"
            :rules="[{ required: true, message: '昵称不能为空' }]"
          >
            <a-input
              style="width: 300px"
              v-model:value="form.nickName"
              placeholder="请输入昵称"
            />
          </a-form-item>
          <a-form-item
            label="头像"
            name="portraitUrl"
            :rules="[{ required: true, message: '头像不能为空' }]"
          >
            <UploadImg :maxLength="1" v-model="portraitUrl" />
          </a-form-item>
        </a-form>
        <DetailModal ref="detailRef" />
        <div class="ele-bottom-tool">
          <div class="ele-bottom-tool-actions" style="text-align: center">
            <a-space :size="16">
              <a-button type="" @click="$router.back()"> 返回 </a-button>
              <a-button type="primary" @click="ok" :loading="addLodaing"
                >确认修改</a-button
              >
            </a-space>
          </div>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script lang="ts">
import { useRequest } from "xz-use";
import { computed, defineComponent, provide, ref } from "vue";
import { useRoute } from "vue-router";
import UploadImg from "@/components/UploadImg/index.vue";
import { message } from "ant-design-vue";
import DetailModal from "./detail/index.vue";
import FormItemGroup from "@/components/FormItemGroup.vue";
import { useForm } from "@/use/useForm";
import { userInfoCurrentUser, userInfoCurrentUserInfoSet } from "./server";

const initData = () => {
  return {
    portraitUrl: null,
    nickName: null,
    mobileNo: null,
  };
};

export default defineComponent({
  components: {
    UploadImg,
    DetailModal,
    FormItemGroup,
  },
  setup() {
    const detailRef = ref();
    const { form, formRef, getFieldsValue } = useForm(initData());
    const portraitUrl = computed({
      get: () => (form.value.portraitUrl ? [form.value.portraitUrl] : []),
      set: ([val]) => (form.value.portraitUrl = val),
    });

    //提交表单接口
    const { run: addRun, loading: addLodaing } = useRequest(
      userInfoCurrentUserInfoSet,
      {
        manual: true,
        onSuccess: () => {
          message.success("操作成功");
        },
      }
    );
    //请求详情接口
    const { run: detailRun, data: userInfo } = useRequest(
      () => {
        return userInfoCurrentUser();
      },
      {
        onSuccess(data) {
          console.log(data);
          form.value = getFieldsValue(data);
        },
      }
    );

    //提交
    const ok = async () => {
      try {
        await formRef.value.validate();
        addRun({
          ...form.value,
        });
      } catch (error) {
        console.log(error);
      }
    };
    provide("userInfo", userInfo);

    return {
      ok,
      formRef,
      form,
      addLodaing,
      detailRef,
      portraitUrl,
    };
  },
});
</script>
