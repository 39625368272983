<template>
  <a-layout class="fixed-header">
    <Header
      :hideMenu="true"
      :hideApplyCenter="hideApplyCenter"
      :hideAuthQuery="hideAuthQuery"
    />
    <a-layout>
      <a-layout-content class="content">
        <router-view />
      </a-layout-content>
      <a-layout-footer class="footer">
        Copyright © 2017-{{ new Date().getFullYear() }}
        杭州象征信息技术有限公司
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>
<script lang="ts">
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import Header from "./components/Header.vue";

export default defineComponent({
  components: { Header },
  setup() {
    const route = useRoute();
    const hideApplyCenter = computed(() => {
      return (
        route.path === "/system/selectSystem" ||
        route.path === "/auth/authorize"
      );
    });
    const hideAuthQuery = computed(() => {
      return route.path !== "/auth/authorize";
    });
    return {
      hideApplyCenter,
      hideAuthQuery,
    };
  },
});
</script>
<style lang="less" scoped>
.fixed-header {
  padding-top: 48px;
}
.content {
  background: #ebedf1;
  padding: 16px;
  min-height: calc(100vh - 48px - 66px);
}

.footer {
  text-align: center;
  color: rgb(147, 147, 147);
  background: #ebedf1;
}
</style>
