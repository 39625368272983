<template>
  <a-modal
    :visible="visible"
    :width="600"
    title="修改密码"
    :maskClosable="false"
    :destroyOnClose="true"
    @cancel="close"
    @ok="ok"
  >
    <a-form
      v-bind="{
        labelCol: { md: { span: 4 }, sm: { span: 24 } },
        wrapperCol: { md: { span: 19 }, sm: { span: 24 } },
      }"
      ref="formRef"
      :model="form"
    >
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane forceRender :key="1" tab="旧密码"> </a-tab-pane>
        <a-tab-pane forceRender :key="2" tab="验证码"> </a-tab-pane>
      </a-tabs>
      <template v-if="activeKey == 2">
        <a-form-item label="用户手机号">
          {{ ` +86 ${userInfo.mobileNo}` }}
        </a-form-item>
        <a-form-item
          label="请输入验证码"
          name="mobileCode"
          :rules="[{ required: true, message: '验证码不能为空' }]"
        >
          <a-input
            size="large"
            v-model:value="form.mobileCode"
            placeholder="请输入验证码"
          >
            <template #suffix>
              <a-button
                :disabled="countdown > 0"
                type="primary"
                @click="
                  () => {
                    setTargetDate(Date.now() + 20000);
                    sendRun({
                      type: 2,
                      mobileNo: userInfo.mobileNo,
                    });
                  }
                "
              >
                {{
                  countdown > 0
                    ? `${Math.round(countdown / 1000)}秒后`
                    : "获取验证码"
                }}</a-button
              >
            </template>
          </a-input>
        </a-form-item>
        <a-form-item
          label="请输入新密码"
          name="newPassword"
          :rules="[
            {
              validator: validatePass,
            },
          ]"
        >
          <a-input-password
            size="large"
            v-model:value="form.newPassword"
            placeholder="请输入新密码"
          />
        </a-form-item>
        <a-form-item
          label="确认新密码"
          name="confirmPassword"
          :rules="[
            {
              validator: validateConfirmPass,
            },
          ]"
        >
          <a-input-password
            size="large"
            v-model:value="form.confirmPassword"
            placeholder="请输入确认新密码"
          />
        </a-form-item>
      </template>
      <template v-if="activeKey == 1">
        <a-form-item
          label="请输入旧密码"
          name="oldPassword"
          :rules="[{ required: true, message: '旧密码不能为空' }]"
        >
          <a-input-password
            size="large"
            v-model:value="form.oldPassword"
            placeholder="请输入旧密码"
          />
        </a-form-item>
        <a-form-item
          label="请输入新密码"
          name="newPassword"
          :rules="[
            {
              validator: validatePass,
            },
          ]"
        >
          <a-input-password
            size="large"
            v-model:value="form.newPassword"
            placeholder="请输入新密码"
          />
        </a-form-item>
        <a-form-item
          label="确认新密码"
          name="confirmPassword"
          :rules="[
            {
              validator: validateConfirmPass,
            },
          ]"
        >
          <a-input-password
            size="large"
            v-model:value="form.confirmPassword"
            placeholder="请输入确认新密码"
          />
        </a-form-item>
      </template>
    </a-form>
  </a-modal>
</template>

<script lang="ts">
import { inject, ref } from "vue";
import { useRequest, useCountDown } from "xz-use";
import { message } from "ant-design-vue";
import { useForm } from "@/use/useForm";
import { commonMsgsend, userInfoUpdatePassword } from "../server";
import { RuleObject } from "ant-design-vue/lib/form/interface";

export default {
  components: {
    // UploadImg,
  },
  setup(props, { expose }) {
    const { formRef, form, reset } = useForm({
      newPassword: "",
      oldPassword: "",
      mobileCode: null,
      confirmPassword: "",
    });
    const activeKey = ref(1);
    const detailRef = ref();
    const visible = ref();
    const userInfo: any = inject("userInfo");
    const { countdown, setTargetDate } = useCountDown();
    //添加和更新
    const { run: addRun } = useRequest(
      (params) => userInfoUpdatePassword(params),
      {
        manual: true,
        onSuccess: () => {
          message.success("操作成功");
          close();
        },
      }
    );

    const { run: sendRun } = useRequest((params) => commonMsgsend(params), {
      manual: true,
      onSuccess: () => {
        message.success("操作成功");
      },
    });

    let validatePass = async (rule: RuleObject, value: string) => {
      if (value === "") {
        return Promise.reject("请输入密码");
      } else {
        if (form.value.confirmPassword !== "") {
          formRef.value.validateFields("confirmPassword");
        }
        return Promise.resolve();
      }
    };
    let validateConfirmPass = async (rule: RuleObject, value: string) => {
      if (value === "") {
        return Promise.reject("请再一次输入密码确认");
      } else if (value !== form.value.newPassword) {
        return Promise.reject("两次输入的密码不一致");
      } else {
        return Promise.resolve();
      }
    };

    //打开
    const open = () => {
      visible.value = true;
    };

    const close = () => {
      reset();
      visible.value = false;
    };

    //提交
    const ok = async () => {
      try {
        //检验表单
        await formRef.value.validate();
        await addRun({ ...form.value, type: activeKey.value } as any);
      } catch (error) {
        console.log(error);
      }
    };
    expose({
      open,
      close,
    });
    return {
      detailRef,
      visible,
      formRef,
      form,
      activeKey,
      ok,
      close,
      sendRun,
      userInfo,
      countdown,
      setTargetDate,
      Date,
      validatePass,
      validateConfirmPass,
      Math,
    };
  },
};
</script>
