
import { inject, ref } from "vue";
import { useRequest, useCountDown } from "xz-use";
import { message } from "ant-design-vue";
import { useForm } from "@/use/useForm";
import { commonMsgsend, userInfoUpdatePassword } from "../server";
import { RuleObject } from "ant-design-vue/lib/form/interface";

export default {
  components: {
    // UploadImg,
  },
  setup(props, { expose }) {
    const { formRef, form, reset } = useForm({
      newPassword: "",
      oldPassword: "",
      mobileCode: null,
      confirmPassword: "",
    });
    const activeKey = ref(1);
    const detailRef = ref();
    const visible = ref();
    const userInfo: any = inject("userInfo");
    const { countdown, setTargetDate } = useCountDown();
    //添加和更新
    const { run: addRun } = useRequest(
      (params) => userInfoUpdatePassword(params),
      {
        manual: true,
        onSuccess: () => {
          message.success("操作成功");
          close();
        },
      }
    );

    const { run: sendRun } = useRequest((params) => commonMsgsend(params), {
      manual: true,
      onSuccess: () => {
        message.success("操作成功");
      },
    });

    let validatePass = async (rule: RuleObject, value: string) => {
      if (value === "") {
        return Promise.reject("请输入密码");
      } else {
        if (form.value.confirmPassword !== "") {
          formRef.value.validateFields("confirmPassword");
        }
        return Promise.resolve();
      }
    };
    let validateConfirmPass = async (rule: RuleObject, value: string) => {
      if (value === "") {
        return Promise.reject("请再一次输入密码确认");
      } else if (value !== form.value.newPassword) {
        return Promise.reject("两次输入的密码不一致");
      } else {
        return Promise.resolve();
      }
    };

    //打开
    const open = () => {
      visible.value = true;
    };

    const close = () => {
      reset();
      visible.value = false;
    };

    //提交
    const ok = async () => {
      try {
        //检验表单
        await formRef.value.validate();
        await addRun({ ...form.value, type: activeKey.value } as any);
      } catch (error) {
        console.log(error);
      }
    };
    expose({
      open,
      close,
    });
    return {
      detailRef,
      visible,
      formRef,
      form,
      activeKey,
      ok,
      close,
      sendRun,
      userInfo,
      countdown,
      setTargetDate,
      Date,
      validatePass,
      validateConfirmPass,
      Math,
    };
  },
};
