import { computed, ref } from "vue";
import { useRequest } from "xz-use";
import {
  XzcloudApplicationAddParams,
  XzcloudApplicationCurrentUserSysListParams,
  xzcloudApplicationCurrentUserSysList,
} from "./service";

export const getDefaultFormValue = () => ({
  applicationContent: "",
  applicationName: "",
  applicationType: 0,
  applyReason: "",
  sysList: null,
});
export const useForm = () => {
  const form = ref<XzcloudApplicationAddParams>(getDefaultFormValue());
  const formRef = ref<any>(null);
  return { form, formRef };
};

export const getDefaultUserSysFormValue = () => ({
  sysName: "",
  sysTypeList: [],
});
export const useUserSysForm = () => {
  const userSysForm = ref<XzcloudApplicationCurrentUserSysListParams>(
    getDefaultUserSysFormValue()
  );
  const userSysFormRef = ref<any>(null);
  return { userSysForm, userSysFormRef };
};

// 系统类型
export const allSysTypeList = [
  { id: 0, label: "外部" },
  { id: 1, label: "零售" },
  { id: 2, label: "GMC会员" },
  { id: 3, label: "三方" },
  { id: 4, label: "微商城" },
];

export const useTable = ({ userSysForm }) => {
  const userSysTableRes = useRequest(() =>
    xzcloudApplicationCurrentUserSysList({
      ...userSysForm.value,
      outer: true,
    })
  );

  const columns = [
    {
      title: "系统类型",
      dataIndex: "sysType",
      width: 100,
      customRender: ({ text }) => {
        return allSysTypeList.find((it) => it.id === text)?.label || "";
      },
    },
    {
      title: "系统",
      width: 200,
      dataIndex: "sysName",
      slots: { customRender: "sysName" },
    },
    { title: "状态", width: 100, dataIndex: "status" },
  ];

  const columnsWidth = computed(() => {
    return columns.reduce((res, item) => {
      return res + item.width;
    }, 0);
  });
  return {
    userSysTableRes,
    columns,
    columnsWidth,
  };
};
