
import { message } from "ant-design-vue";
import { defineComponent, computed } from "vue";
import { useModalState, useRequest } from "xz-use";
import { xzcloudTeamAdd } from "../service";
import { useForm, getDefaultFormValue } from "./use";
import useUser from "@/use/useUser";

export default defineComponent({
  setup(props, { expose, emit }) {
    const { form, formRef } = useForm();
    const { mobileNo } = useUser();

    // Modal状态管理
    const { visible, handleOpen, handleClose } = useModalState({
      afterClose: () => {
        form.value = getDefaultFormValue();
        formRef.value.clearValidate();
      },
    });

    expose({
      open: handleOpen,
      close: handleClose,
    });
    //     computed(() => {
    // data-count
    //     });
    const dataCount = computed(() => {
      return `${form.value.teamName.length} / 15`;
    });

    const { run: addRun, loading } = useRequest(xzcloudTeamAdd, {
      manual: true,
      onSuccess: () => {
        message.success("创建成功");
        emit("reload");
        handleClose();
      },
    });

    const handleOk = async () => {
      try {
        await formRef.value?.validateFields();
        await addRun(form.value);
      } catch (e) {
        console.error(e);
      }
    };

    return {
      loading,
      form,
      formRef,
      visible,
      mobileNo,
      dataCount,
      handleClose,
      handleOk,
      formLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
      },
    };
  },
});
