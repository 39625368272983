<template>
  <a-layout-header class="header">
    <div class="switch-icon">
      <IconFont type="iconbianzu11" />
    </div>
    <div class="apply-name">象征云</div>
    <div class="apply-title" v-if="!hideAuthQuery">应用市场授权查询</div>
    <div class="menu">
      <!-- <Menu v-if="!hideMenu" /> -->
      <a-menu
        v-model:selectedKeys="selectedKeys"
        mode="horizontal"
        v-if="!hideApplyCenter"
      >
        <a-menu-item
          key="/system/applyCenter"
          @click="$router.push('/system/applyCenter')"
        >
          应用中心
        </a-menu-item>
      </a-menu>
    </div>
    <div class="header-tool">
      <!-- <div class="header-tool-item" v-if="!hideApplyCenter">
        <ApplyCenter />
      </div> -->
      <div class="header-tool-item">
        <AvatarDropdown />
      </div>
    </div>
  </a-layout-header>
</template>
<script lang="ts">
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import IconFont from "@/components/IconFont";
import Menu from "./Menu.vue";
import AvatarDropdown from "./AvatarDropdown.vue";
import ApplyCenter from "./ApplyCenter.vue";

export default defineComponent({
  props: {
    hideMenu: {
      type: Boolean,
      default: false,
    },
    hideApplyCenter: {
      type: Boolean,
      default: false,
    },
    hideAuthQuery: {
      type: Boolean,
      default: true,
    },
  },
  components: { AvatarDropdown, IconFont },
  setup() {
    const route = useRoute();
    const selectedKeys = computed(() => {
      return route.path === "/system/applyCenter" ? [route.path] : [];
    });
    return {
      selectedKeys,
    };
  },
});
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 48px;
  line-height: 48px;
  background: white;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 0;
  padding-left: 20px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  .switch-icon {
    flex: 0 0 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8c8c8c;
    cursor: pointer;
    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }
  .apply-name {
    margin-left: 15px;
    flex: 0 0 122px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #262626;
  }
  .menu {
    flex: auto;
    &::v-deep {
      .ant-menu {
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
      }
      .ant-menu-horizontal {
        border-bottom: none;
        & > .ant-menu-item::after,
        & > .ant-menu-submenu::after {
          bottom: 8px;
          height: 4px;
          background: transparent;
          border-radius: 3px;
          border-bottom: none;
          width: 30%;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .ant-menu-horizontal:not(.ant-menu-dark)
        > .ant-menu-item-selected::after {
        background: #00a389;
      }
    }
  }
  .header-tool {
    display: flex;
    flex-shrink: 0;
    padding: 0 12px;
    .header-tool-item {
      padding: 0 12px;
      font-size: 12px;
      transition: color 0.2s, background-color 0.2s;
      cursor: pointer;
      &:hover {
        background: rgba(0, 0, 0, 0.025);
      }
    }
  }
}

.dropdown-menu-item__content {
  position: relative;
  &:hover {
    .switch-btn,
    .glzh-btn {
      display: block;
    }
  }
  .switch-btn,
  .glzh-btn {
    display: none;
    color: #1969f1;
    position: absolute;
    top: 50%;
    right: 0;
    transform: transLateY(-50%);
  }
}
</style>
