<template>
  <div class="ele-body">
    <a-table
      size="small"
      :pagination="false"
      :columns="columns"
      :dataSource="dataSource"
    />
  </div>
</template>
<script lang="tsx">
import { defineComponent, reactive, computed } from "vue";
import { httpGet } from "@/config/axios-config";
import { useRequest } from "xz-use";

export default defineComponent({
  setup() {
    const { data } = useRequest(
      () => httpGet("/xzcloud/messageStrategy/allSys"),
      {
        initialData: {
          receiver: [],
          organizer: [],
        },
      }
    );

    const isChecked = (organizer, receiver) => {
      // 发起方  接收方
      if (!dataMap[organizer.sysId]) return false;
      return !!dataMap[organizer.sysId].find(
        (it) => it.sysId === receiver.sysId
      );
    };

    const columns = computed(() => {
      return [
        {
          title: (
            <div class="fisrt-th">
              <div class="fisrt-th-x">接收方</div>
              <div class="fisrt-th-y">发起方</div>
              <a-divider type="vertical" />
            </div>
          ),
          width: 100,
          dataIndex: "sysName",
        },
      ].concat(
        //接收方
        (data.value as any).receiver.map((receiver) => {
          return {
            title: receiver.sysName,
            // 发起方
            customRender: ({ record: organizer }) => {
              if (receiver.sysId === organizer.sysId) {
                return "-";
              }
              return (
                <a-checkbox
                  checked={isChecked(organizer, receiver)}
                  onChange={(e) => handleChange(e, organizer, receiver)}
                />
              );
            },
          };
        })
      );
    });

    const dataSource = computed(() => {
      return (data.value as any).organizer;
    });

    const dataMap = reactive({});

    const handleChange = (e, organizer, receiver) => {
      const { checked } = e.target;
      if (!dataMap[organizer.sysId]) {
        dataMap[organizer.sysId] = [];
      }
      if (checked) {
        if (
          !dataMap[organizer.sysId].find((it) => it.sysId === receiver.sysId)
        ) {
          // 不存在push
          dataMap[organizer.sysId].push(receiver);
        }
      } else {
        dataMap[organizer.sysId] = dataMap[organizer.sysId].filter(
          (it) => it.sysId !== receiver.sysId
        );
      }
    };

    return { columns, dataSource, dataMap };
  },
});
</script>
<style scoped lang="less">
.ele-body {
  &::v-deep(.ant-table-thead > tr:first-child > th:first-child) {
    position: relative;
  }
}
.fisrt-th-x {
  position: absolute;
  top: 8px;
  right: 0px;
}
.fisrt-th-y {
  position: absolute;
  bottom: 0px;
  left: 8px;
}
.ant-divider-vertical {
  position: absolute;
  transform: rotate(-70deg); /* 需要手调 ，斜线的角度*/
  transform-origin: top;
  top: 1px;
  left: -8px;
  height: 8.3em;
  background: #fafafa;
}
</style>
