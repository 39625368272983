import { httpGet, httpPost } from "@/config/axios-config";
import { stringify } from "querystring";

// 新增应用
export interface XzcloudApplicationAddParams {
  /* 应用简介 */
  applicationContent: string;

  /* 应用类型 */
  applicationType: number;

  /* 应用名称 */
  applicationName: string;

  /* 申请理由 */
  applyReason: string;

  /* 系统集合 */
  sysList: SysList[] | null;
}
export interface SysList {
  /* 系统id */
  sysId: number;

  /* 系统类型 */
  sysType: number;

  sysCode: string;
}
export const xzcloudApplicationAdd = (params: XzcloudApplicationAddParams) =>
  httpPost("/xzcloud/application/add", params);

// 工具型更新接口
export interface XzcloudApplicationUpdateToolApplicationParams
  extends XzcloudApplicationAddParams {
  /* 应用id */
  applicationId: number;
}
export interface XzcloudApplicationUpdateToolApplicationRes {}
export const xzcloudApplicationUpdateToolApplication = (
  params: XzcloudApplicationUpdateToolApplicationParams
) =>
  httpPost<XzcloudApplicationUpdateToolApplicationRes>(
    "/xzcloud/application/update/tool/application",
    params
  );

// 授权系统列表查询
export interface XzcloudApplicationCurrentUserSysListParams {
  /* 系统名称 */
  sysName: string;

  /* 系统类型列表 */
  sysTypeList: number[] | null;
}
export interface XzcloudApplicationCurrentUserSysListRes {
  /* 环境 */
  env: string;

  /* 状态 */
  status: string;

  /* 系统ID */
  sysId: number;

  /* 系统名称 */
  sysName: string;

  /* 系统类型（已翻译） */
  sysType: string;
}
export const xzcloudApplicationCurrentUserSysList = (
  params: XzcloudApplicationCurrentUserSysListParams
) =>
  httpGet<XzcloudApplicationCurrentUserSysListRes[]>(
    `/xzcloud/application/currentUserSysList?${stringify({ ...params })}`
  );
