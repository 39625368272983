
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import IconFont from "@/components/IconFont";
import Menu from "./Menu.vue";
import AvatarDropdown from "./AvatarDropdown.vue";
import ApplyCenter from "./ApplyCenter.vue";

export default defineComponent({
  props: {
    hideMenu: {
      type: Boolean,
      default: false,
    },
    hideApplyCenter: {
      type: Boolean,
      default: false,
    },
    hideAuthQuery: {
      type: Boolean,
      default: true,
    },
  },
  components: { AvatarDropdown, IconFont },
  setup() {
    const route = useRoute();
    const selectedKeys = computed(() => {
      return route.path === "/system/applyCenter" ? [route.path] : [];
    });
    return {
      selectedKeys,
    };
  },
});
