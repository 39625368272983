import { ref } from "vue";
import { XzcloudTeamAddParams } from "../service";

export const getDefaultFormValue = () => ({
  teamContent: "",
  teamName: "",
});
export const useForm = () => {
  const form = ref<XzcloudTeamAddParams>(getDefaultFormValue());
  const formRef = ref<any>(null);
  return { form, formRef };
};
