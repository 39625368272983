import { httpGet, httpPost } from "@/config/axios-config";
import { PageParams, PageRes, PaginatedParams } from "@/services/public";

// 新增
export interface XzcloudTeamAddParams {
  /* 团队概况 */
  teamContent: string;

  /* 团队名称 */
  teamName: string;
}
export const xzcloudTeamAdd = (params: XzcloudTeamAddParams) =>
  httpPost("/xzcloud/team/add", params);

// 详情
export interface XzcloudTeamInfoRes {
  /* 管理员手机号 */
  managerMobile: string;

  /* 团队说明 */
  teamContent: string;

  /* 团队ID */
  teamId: string;

  /* 团队名称 */
  teamName: string;
}
export const xzcloudTeamInfo = () =>
  httpPost<XzcloudTeamInfoRes>("/xzcloud/team/info");

// 列表查询
export interface XzcloudTeamSelectListParams {
  /* 参数 */
  param: string;
}
export interface XzcloudTeamSelectListRes {
  /* 创建时间 */
  createDate: string;

  /* 团队说明 */
  teamContent: string;

  /* 团队ID */
  teamId: string;

  /* 团队名称 */
  teamName: string;
}
export const xzcloudTeamSelectList = (params: XzcloudTeamSelectListParams) =>
  httpGet<XzcloudTeamSelectListRes[]>("/xzcloud/team/selectList", params);
